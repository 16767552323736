import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Article from "../components/article"
import RichText from "../components/richText"
import BackgroundImages from "../components/backgroundImages"

const EventPage = ({
   data: {
     contentful
   }
 }) => {

  return (
    <Layout title={contentful.title}>
      <Article title={contentful.title}>
        <RichText data={contentful.content} />
        <BackgroundImages images={contentful.images}/>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!)  {
    contentful: contentfulEvents(id: {eq: $id}) {
      title
      content {
        content
        json
      }
      images {
          title
          file {
              url
          }
          sizes(quality: 50) {
              ...GatsbyContentfulSizes_withWebp
          }
          fluid(maxWidth: 500) {
              ...GatsbyContentfulFluid_withWebp
          }
      }        
    }
  }
`

export default EventPage
