import React from 'react'
import styled from "@emotion/styled"

const ArticleStyled = styled.article`
  padding: 20px;
  text-align: center;
  
  h1 {
    font-size: 1.6em;
  }
  
  p {
    line-height: 1.6;
  }
  
  @media (min-width: 641px) {
    h1 {
      font-size: 2em;
    }
    
    p {
      font-size: 1.4em;
    }
  }
`

const Article = ({ children, title }) => {
  return (
    <ArticleStyled>
      <h1>{title}</h1>
      {children}
    </ArticleStyled>
  )
}

export default Article

