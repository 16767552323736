import React from 'react'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import styled from "@emotion/styled"

const RichText = ({data}) => {

  // Define Rich Text Content
  const Title = styled.h1``
  const Text = styled.p``
  const Bold = styled.strong``

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <Title>{children}</Title>,
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    }
  }

  return (
    documentToReactComponents(data.json, options)
  )
}

export default RichText

